<template>
    <div>
        <section class="layui-main ybody">
            <div class="bottm-four">
                <div class="xitems" @click="goChat()">
                    <div class="left">
                        <img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/gongyi/gyicon61.png">
                        <img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/gongyi/gyicon61_a.png">
                    </div>
                    <div class="right">
                        <h3>申请打样</h3>
                        <p>提供免费打样服务</p>
                    </div>
                </div>
                <div class="xitems" ref="tels" @click="goTeler()">
                    <div class="left">
                        <img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/gongyi/gyicon62.png"><img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/gongyi/gyicon62_a.png">
                    </div>
                    <div class="right">
                        <h3>全国热线</h3>
                        <p>027-87180285</p>
                    </div>
                </div>
                <div class="xitems" @click="goChat()">
                    <div class="left">
                        <img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/gongyi/gyicon63.png"><img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/gongyi/gyicon63_a.png">
                    </div>
                    <div class="right">
                        <h3>立即咨询</h3>
                        <p>24小时在线为您服务</p>
                    </div>
                </div>
                <div class="xitems" @click="goChat()">
                    <div class="left">
                        <img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/gongyi/gyicon64.png"><img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/gongyi/gyicon64_a.png">
                    </div>
                    <div class="right">
                        <h3>在线预订</h3>
                        <p>在线留言预订产品</p>
                    </div>
                </div>
            </div>
        </section>

        <footer>
            <div class="footer_t ">
                <div class="layui-main">
                    <div class="footerf-l">
                        <h3><img
                                src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/bottom_phone.png">
                        </h3>
                        <p>武汉武钢华工激光大型装备有限公司</p>
                        <p>地址：湖北省武汉市东湖新技术开发区南新街9号</p>
                        <p>电子邮箱：info@wghglaser.com/传真：&nbsp;027-87180211</p>
                    </div>
                    <div class="footerf-z">
                        <div class="wei">
                            <p>关注微信</p>
                            <img src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/matrix2.jpg">
                        </div>
                        <div class="wei">
                            <p>关注微博</p>
                            <img src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/lazer_weibo.jpg">
                        </div>
                    </div>
                    <div class="footerf-r">
                        <p>快速直达</p>
                        <div class="selection">
                            <select v-model="link">
                                <!-- <ul id="" class="nav navbar-nav"> -->
                                    <option value="/product/3685">产品中心</option>
                                    <option value="/case/3688">行业案例</option>
                                    <option value="/technology/3687">工艺中心</option>
                                <!-- </ul> -->
                            </select>
                            <div class="span" @click="getLink"><img src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/arr_right.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer_f">
                <div class="layui-main">
                    <span>版权所有©2019&nbsp;武汉武钢华工激光大型装备有限公司&nbsp;
                        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">鄂ICP备12016925号-1</a>
                        <img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/ghs.png">
                        <a href="http://www.beian.gov.cn/" target="_blank">鄂公网安备 42018502003559号</a>
                    </span>
                    <span>
                        <!-- <script type="text/javascript">var cnzz_protocol = (("https:" == document.location.protocol) ? "https://" : "http://");document.write(unescape("%3Cspan id='cnzz_stat_icon_1276407047'%3E%3C/span%3E%3Cscript src='" + cnzz_protocol + "s23.cnzz.com/z_stat.php%3Fid%3D1276407047%26show%3Dpic1' type='text/javascript'%3E%3C/script%3E"));</script> -->
                    </span>
                    <span class="linkright">
                        <a href="/">网站首页</a>
                        <a href="/aboutUs/3691/3730">联系我们</a>
                        <!-- <a href="javascript:;">网站地图</a> -->
                        <a href="/terms">使用条款</a>
                    </span>
                </div>
            </div>
        </footer>
        <div class="xrightbar">
            <ul>
                <li>
                    <a href="javascript:;" @click="goChat()" title="客服">
                        <img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/side_kefu.png">
                        <img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/side_kefu_a.png">
                    </a>
                </li>
                <!--li>
				<a href="/portal/page/index/id/1.html" title="在线留言">
					<img src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/side_liuyan.png" >
					<img src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/side_liuyan_a.png" >
				</a>
			</li!-->
                <li>
                    <a href="tel:02787180285" data-agl-cvt="2" title="联系电话">
                        <img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/side_tel.png">
                        <img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/side_tel_a.png">
                    </a>
                    <div class="popper telphone">
                        <p>027-87180285</p>
                    </div>
                </li>
                <li>
                    <a href="javascript:;" title="二维码">
                        <img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/side_ewm.png">
                        <img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/side_ewm_a.png">
                    </a>
                    <div class="popper">
                        <img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/matrix.jpg">
                    </div>
                </li>
                <li>
                    <a href="javascript:;" @click="scrollToTop" title="返回顶部">
                        <img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/side_toper.png">
                        <img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/side_toper_a.png">
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            url: 'https://www.wghglaser.com/',
            link:'',
        }
    },

    created () {
    },

    mounted () {

    },
    methods: {
        getLink(){
            this.$router.push(this.link)
        },
        goChat () {
            window.open('https://affim.baidu.com/cps/chat?siteId=2298312&userId=5878546&siteToken=3ad89c91612a1981745174b1dc92911f')
        },
        goTeler () {
            this.$refs.tels.href = `tel:02787180285`
            this.$refs.tels.click()
        },
        scrollToTop () {
            const scrollStep = window.pageYOffset / 20
            // 创建一个定时器
            const scrollInterval = setInterval(() => {
                // 如果已经滚动到了顶部，清除定时器
                if (window.pageYOffset === 0) {
                    clearInterval(scrollInterval)
                }
                // 滚动到上一帧的位置
                window.scroll(0, window.pageYOffset - scrollStep)
            }, 15)
        }
    }
}
</script>