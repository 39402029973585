<template>
    <div>
        <!--头部公共部分-->
        <header>
            <div class="layui-main">
                <div> <img src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/logo.png" alt="武钢华工激光"
                        onclick="window.location.href='/'"> </div>
                <div style="width: 570px;"> <span><img
                            src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/phone_icon.png">&nbsp;&nbsp;<a
                            href="tel:02787180285" data-agl-cvt="2" title="联系电话">027-87180285</a></span>
                    <div class="layui-ss layui-form">
                        <input id="kwdinput" type="text" v-model="keyword" name="keyword" value="" placeholder="请输入关键字" autocomplete="off"
                            class="layui-input">
                        <button @click="goSearch()"><img src="https://res.baowugroup.com/wghglaser.com/public/themes/jiguang/public/assets/images/search_icon.png"
                                alt=""></button>

                    </div>
                    <span style="margin-left: 32px;float: left;font-size:14px">
                        <a href="http://www.wghglaser.com/">中&nbsp;</a> <span
                            style="float: none; margin-left: 0;">/</span><a
                            href="http://en.wghglaser.com/">&nbsp;EN</a>
                    </span>

                </div>
            </div>
            <div @mouseleave="addClass('')">
                <div class="xnaver">
                    <ul class="xparent" >
                        <li class="xitems" href="/">
                            <a href="/" target="">首页</a>
                        </li>
                        <!-- :class="[isActive==index?'active' : '','xitems']" -->
                        <li @mouseover="addClass(index)" class="xitems" v-for="(item,index) in datas" :key="index" v-if="index<7">
                            <a href="javascript:;" @click="linkUrl(item)">{{item.nodeName}}</a>
                        </li>
                    </ul>
                </div>
                <div v-if="isActive!==''" @mouseleave="addClass('')" class="xdroper">
                    <div v-if="isActive==0" class="xchildren xproduct">
                        <div class="xrows">
                            <div class="xitems" v-for="(item,index) in datas[0].childs" v-if="index>0" :key="index">
                                <div class="xtop">
                                    <img :src="item.imageUrl"
                                        :alt="item.nodeName" />
                                    <a href="javascript:;" @click="productInfo('productDetails',{nodeId: item.nodeId,cnodeId:item.childs[0].nodeId})">
                                        <h4 class="xol">{{item.nodeName}}</h4>
                                    </a>
                                </div>
                                <div class="xbottom">
                                    <a v-for="(citem,cindex) in item.childs" :key="cindex" @click="productInfo('productDetails',{nodeId: item.nodeId,cnodeId:citem.nodeId})" href="javascript:;" class="xol">{{citem.nodeName}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="isActive==index && isActive!=0" v-for="(item,index) in datas" :key='index' class="xchildren xother" href="javascript:;">
                        <div class="xleft">
                            <div class="hrefs">
                                <div  v-if="item.nodeName == '行业案例'">
                                    <div class="xitems halfer">
                                        <a v-for="(citem,cindex) in item.l_list" v-if="cindex<6" :key='cindex' href="javascript:;" @click="childLinkUrl(item,citem)">{{citem.nodeName || citem.title}}</a>
                                    </div>
                                    <div class="xitems halfer">
                                        <a v-for="(citem,cindex) in item.l_list" v-if="cindex>=6" :key='cindex' href="javascript:;" @click="childLinkUrl(item,citem)">{{citem.nodeName || citem.title}}</a>
                                    </div>
                                </div>
                                <div v-else class="xitems">
                                    <a v-for="(citem,cindex) in item.l_list" :key='cindex'  href="javascript:;" @click="childLinkUrl(item,citem)">{{citem.nodeName || citem.title}}</a>
                                </div>
                                
                            </div>
                        </div>
                        <div class="xright">
                            <div class="xitems" @click="childLinkUrl(item,citem)" v-for="(citem,cindex) in item.r_list" :key='cindex' v-if="cindex<3">
                                <a href="javascript:;">
                                    <h4 class="xol">{{citem.title}}</h4>
                                </a>
                                <a href="javascript:;">
                                    <div v-html="citem.content" style="height: 52px; overflow: hidden;"></div>
                                </a>
                                <a href="javascript:;"><img :src="citem.imageUrl" /></a>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            
        </header>
    </div>
</template>
<script>
import {
    API_GetMenuList,
    API_getNewsListAll
} from "@/fetch/api";
export default {
    data () {
        return {
            isActive: '',
            datas:[],
            keyword:'',
        }
    },
    beforeMount() {
        this.actives = this.$route.params.nodeId
        // if (localStorage.getItem("storeState")) {
        //     let datas = JSON.parse(localStorage.getItem("storeState"));
        //     this.datas = datas.menuList;
        //     this.$store.commit("dataObj", datas);
        // } else {
            
        // }
        API_GetMenuList()
                .then(res => {
                    let data = {
                        firstLevelMenuList: [],
                        firstLevelMenuListById: {},
                        secondLevelMenuListById: {},
                        menuList:[]
                    };
                    let dataObj = res.data[0].childs
                    dataObj.forEach(i => {
                        // data.firstLevelMenuList.push(data.menuList[i]);
                        // data.firstLevelMenuListById[data.menuList[i].nodeId] =  data.menuList[i];
                        if(i.childs){
                            i.r_list = []
                            i.l_list = i.childs
                            API_getNewsListAll({
                                pageNo: 0,
                                pageSize: 100,
                                condition: { nodeId: i.nodeId,isColor:1}
                            })
                                .then(res => {
                                    let list= res.data.data
                                    list.forEach(j => {
                                        if((j.nodeId == i.nodeId || j.node.parentId == i.nodeId)){
                                            i.r_list.push(j)
                                        }
                                    });
                                })
                                .catch(err => {
                                    console.log(err)
                                })
                        }else{
                            i.r_list = []
                            i.l_list = []
                            API_getNewsListAll({
                                pageNo: 0,
                                pageSize: 100,
                                condition: { nodeId: i.nodeId}
                                // condition: { nodeIds: [3687,3688],isColor:1}
                            })
                                .then(res => {
                                    let list= res.data.data
                                    list.forEach(j => {
                                        if(j.nodeId == i.nodeId && j.contentGroups[0]?.contentGroupName =='pc'){
                                            i.l_list.push(j)
                                            if(j.isColor == 1){
                                                i.r_list.push(j)
                                            }
                                            
                                        }
                                    });
                                })
                                .catch(err => {
                                    console.log(err)
                                })
                        } 
                    });
                   
                    this.datas = dataObj
                    data.menuList = dataObj
                    this.$store.commit("dataObj", data);
                })
                .catch(error => {
                    console.log(error)
                });
       
    },
    created () {
    },

    mounted () {
    },
    methods: {
        goSearch(){
            if(this.keyword!=''){
                this.$router.push(`/search?keyword=${this.keyword}`)
            }
        },
        linkUrl(item){
            let cnodeId = item.childs? item.childs[0].nodeId : ''
            let params =  { nodeId: item.nodeId,cnodeId:cnodeId}
            switch (item.nodeName) {
                case "产品中心":
                    this.$router.push({ name: 'product', params: params})
                    break;
                case "加工中心":
                    this.$router.push({ name: 'processing', params: params})
                    break;
                case "工艺中心":
                     this.$router.push({ name: 'technology', params: params})
                    break;
                case "行业案例":
                    this.$router.push({ name: 'case', params: params})
                    break;
                case "服务支持":
                    this.$router.push({ name: 'service', params: params})
                    break;
                case "新闻中心":
                    this.$router.push({ name: 'newsList', params: params})
                    break;
                case "关于我们":
                    this.$router.push({ name: 'aboutUs', params: params})
                    break;
            }
        },
        childLinkUrl(item,citem){
            switch (item.nodeName) {
                case "加工中心":
                    this.$router.push({ name: 'processingDetails', params: { nodeId: item.nodeId,cnodeId:citem.nodeId}})
                    break;
                case "工艺中心": 
                    this.$router.push({ path: `/info/technology/${item.nodeId}?id=${citem.id}`})
                    break;
                case "行业案例":
                    this.$router.push({ path: `/info/case/${item.nodeId}?id=${citem.id}`})
                    break;
                case "服务支持":
                    if(citem.id){
                        this.$router.push({ path: `/article/service/${item.nodeId}/${citem.nodeId}?id=${citem.id}`})
                    }else{
                        this.$router.push({ path: `/service/${item.nodeId}/${citem.nodeId}`})
                    }
                    break;
                case "新闻中心":
                    if(citem.id){
                        this.$router.push({ path: `/article/newsList/${item.nodeId}/${citem.nodeId}?id=${citem.id}`})
                    }else{
                        this.$router.push({ path: `/newsList/${item.nodeId}/${citem.nodeId}`})
                    }
                    break;
                case "关于我们":
                    this.$router.push({ name: 'aboutUs', params: { nodeId: item.nodeId,cnodeId:citem.nodeId}})
                    break;
            }
        },
        productInfo(name,params){
            this.$router.push({ name: name, params: params})
        },
        addClass(i) {
            this.isActive = i;
        },
    }    
}
</script>
<style scoped>
.active {
    display: block
}
</style>