import Vue from 'vue'
import Router from 'vue-router'
const index = () =>
    import ('@/page/index');
const product = () =>
    import ('@/page/product');
const processing = () =>
    import ('@/page/processing');
const productDetails = () =>
    import ('@/page/productDetails');
const aboutUs = () =>
    import ('@/page/aboutUs');
const newsList = () =>
    import ('@/page/newsList');
const article = () =>
    import ('@/page/article');
const service = () =>
    import ('@/page/service');
const cases = () =>
    import ('@/page/case');
const caseInfo = () =>
    import ('@/page/caseInfo');
const search = () =>
    import ('@/page/search');
const terms = () =>
    import ('@/page/terms');
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)
export default new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        // if (savedPosition && to.meta.keepAlive) {
        //     return savedPosition;
        // }
        return { x: 0, y: 0 };
    },
    routes: [{
            path: '/',
            name: 'index',
            component: index,
            meta: {
                title: '首页'
            }
        },
        {
            path: '/product/:nodeId',
            name: 'product',
            component: product,
            meta: {
                title: '产品分类'
            }
        },
        {
            path: '/processing/:nodeId',
            name: 'processing',
            component: processing,
            meta: {
                title: '加工中心'
            }
        },
        {
            path: '/productDetails/:nodeId/:cnodeId',
            name: 'productDetails',
            component: productDetails,
            meta: {
                title: '产品详情'
            }
        },
        {
            path: '/processingDetails/:nodeId/:cnodeId',
            name: 'processingDetails',
            component: productDetails,
            meta: {
                title: '加工详情'
            }
        },

        {
            path: '/aboutUs/:nodeId/:cnodeId',
            name: 'aboutUs',
            component: aboutUs,
            meta: {
                title: '关于我们'
            }
        },
        {
            path: '/newsList/:nodeId/:cnodeId',
            name: 'newsList',
            component: newsList,
            meta: {
                title: '新闻中心'
            }
        },
        {
            path: '/article/:type/:nodeId/:cnodeId',
            name: 'article',
            component: article,
            meta: {
                title: '文章详情'
            }
        },
        {
            path: '/service/:nodeId/:cnodeId',
            name: 'service',
            component: service,
            meta: {
                title: '服务支持'
            }
        },
        {
            path: '/case/:nodeId',
            name: 'case',
            component: cases,
            meta: {
                title: '行业案例'
            }
        },
        {
            path: '/technology/:nodeId',
            name: 'technology',
            component: cases,
            meta: {
                title: '工艺中心'
            }
        },
        {
            path: '/info/:type/:nodeId',
            name: 'caseInfo',
            component: caseInfo,
            meta: {
                title: '案例详情'
            }
        },
        {
            path: '/search',
            name: 'search',
            component: search,
            meta: {
                title: '搜索'
            }
        },
        {
            path: '/terms',
            name: 'terms',
            component: terms,
            meta: {
                title: '使用条款'
            }
        },

    ]
})